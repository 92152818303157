import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { Message } from "../../../framework/src/Message";
import React from "react";

export const baseURL = require("../../../framework/src/config.js");

interface ISubject {
  updated_at: string;
  id: number;
  created_at: string;
  name: string;
}

type WebResponseJsonForContactUs = { errors: { contact: string[] }[] } & {
  data: {
    type: string;
    id: string;
    attributes: {
      email: string;
      subject_id: number;
      description: string;
      name: string;
      phone_number: string;
    };
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface ContactUsData {
  data: {
    id: string,
    "type": string,
    "attributes": {
        "id": 1,
        "vendor_email": string,
        "vendor_phone_number": string,
        "user_email": string,
        "user_phone_number": string,
        "address": string,
        "created_at": string,
        "updated_at": string
    }
}
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  subjectList: ISubject[];
  contactusData:ContactUsData
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formikContactRef: any;
  carouselItemApiCallId: string = "";
  subjectApiCallId: string = "";
  contactUsApiCallId: string = "";
  getContactUsApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      subjectList: [],
      contactusData:{
        data: {
          id: "",
          type: "",
          attributes: {
            id: 1,
            vendor_email: "",
            vendor_phone_number: "",
            user_email: "",
            user_phone_number: "",
            address: "",
            created_at: "",
            updated_at: ""
          }
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formikContactRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.subjectApiCallId:
          this.setState({
            subjectList: webResponseJson,
            isLoading: false,
          });
          break;
        case this.contactUsApiCallId:
          this.handleContactUsApiResponse(webResponseJson);
          break;
          case this.getContactUsApiCallId:
            this.setState({
              isLoading:false,
              contactusData: webResponseJson
            })
          break;
        default:
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSubjectList();
    this.getContactUsInfo()
  }

  getSubjectList = async () => {
    this.setState({ isLoading: true });
    this.subjectApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/subjects",
      method: "GET",
      contentType: "application/json",
    });
  };

  getContactUsInfo = async () => {
    this.setState({ isLoading: true });
    this.getContactUsApiCallId = await apiCall({
      endPoint: "bx_block_landingpage2/why_choose_us/show_contact_info",
      method: "GET",
    });
  };

  contactUsApiIntegration = async (values: {
    phone_number: string;
    name: string;
    email: string;
    subject_id: string;
    description: string;
  }) => {
    const headers = {
        "Content-Type": "application/json",
    };
    const httpBodydata = {
      data: {
        name: values.name,
        description: values.description,
        email: values.email,
        subject_id: values.subject_id,
        phone_number: `+91${values.phone_number}`,
      },
    }
    const requestApiCall = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contactUsApiCallId = requestApiCall.messageId;
    requestApiCall.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_contact_us/contacts"
    );
    requestApiCall.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    requestApiCall.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       "POST"
    );
    requestApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodydata)
  );
    runEngine.sendMessage(requestApiCall.id, requestApiCall);
    return this.contactUsApiCallId;
}
  handleContactUsApiResponse = (
    webResponseJson: WebResponseJsonForContactUs
  ) => {
    if (webResponseJson.data) {
      toast.success(
        "Thank you for contacting us! We will get back to you shortly."
      );
      if (this.formikContactRef.current) {
        this.formikContactRef.current.resetForm();
      }
    }
    if (webResponseJson.errors) {
      toast.error(webResponseJson.errors[0].contact[0]);
    }
    this.setState({
      isLoading: false,
    });
  };
  // Customizable Area End
}
