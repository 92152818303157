import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData: {
    reference_id: string;
    email: string;
    password: string;
    fullName: string;
    gstNumber:string;
    businessName:string;
    businessCategory:string;
    primaryContact:string;
    secondaryNumber:string;
    showPassword: boolean;
    isChecked: boolean;
    pincode:string;
    isTermsChecked: boolean;
    isPrivacyChecked:boolean;
    day: string, month: string, year: string ;
    serviceProvided:string;
    phoneId:string;
    city:string;
    state:string;
    country:string;
    productOffered:string;
    branchName:string;
    ifscCode:string;
    bankAccountDetails:string;
    paymentMethod:string;
    phoneIdNo:string;
    serviceLocation:string;
    address:string;
    portfolioLink:string;
    socialMediaLink:string;
    primaryContactPersonName:string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        password: "",
        fullName: "",
        businessCategory:"",
        serviceLocation:"",
        bankAccountDetails:"",
        branchName:"",
        gstNumber:"",
        ifscCode:"",
        businessName:"",
        paymentMethod:"",
        pincode:"",
        socialMediaLink:"",
        state:"",
        country:"",
        primaryContact:'',
        address:"",
        city:"",
        secondaryNumber:"",
        showPassword: false,
        reference_id: "",
        serviceProvided:"",
        productOffered:"",
        portfolioLink:"",
        isChecked: false,
        day: "", month: "", year: "" ,
        isTermsChecked: false,
      isPrivacyChecked:false,
      primaryContactPersonName:"",
      phoneId:'',
      phoneIdNo:''
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start

  signUpWithVendorEmail = () => {
    console.log("you submit the form")
  };
   
  handleDelete=()=>{
    console.log("you delete the chip")
  }
  // Customizable Area End
}
