import React from "react";

// Customizable Area Start
import {
  Box,Typography, Avatar,InputLabel,Chip,Button,InputAdornment,IconButton,
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import { imgVisbility, imgVisbilityOff } from "./assets";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import TextInputFiled from "../../../components/src/TextInputField.web";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import SelectField from "../../../../packages/components/src/SelectField.web";
export type touched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
export type  errors = string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }

  handleDescriptionBox = (values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any) => {
    return(
      <Box sx={webstyle.description}>
      <Box sx={webstyle.innerDescription}>
        <InputLabel className="labelClass" style={webstyle.inputText}>Primary contact number<span style={webstyle.star}>*</span></InputLabel>
        <Box sx={webstyle.formInput2}>
          <SelectField sx={{ width: "79px" }}
            name="number"
            data-test-id="monthCheck"
            value={values.month}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            options={this.cityNames()}
            placeholder="+91"
            touched={Boolean(touched.month)}
            helperText={this.handleErrorText(touched.month, errors.month) ? errors.month as string : ""}
            label={""}
          />
          <TextInputFiled sx={{width: {xs: "100%", sm: "22vw", md: "80%", lg: "15vw"}}}
            name="primaryContact"
            data-test-id="primary-contact"
            placeholder="Enter primary contact number"
            value={values.primaryContact}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.primaryContact, errors.primaryContact)}
            helperText={this.handleErrorText(touched.primaryContact, errors.primaryContact) ? errors.primaryContact as string : ""}
          />
        </Box>
      </Box>
      <Box sx={webstyle.innerDescription}>
        <InputLabel className="labelClass" style={webstyle.inputText}>Secondary contact number<span style={webstyle.star}>*</span></InputLabel>
        <Box sx={webstyle.formInput2}>
          <SelectField sx={{ width: "79px" }}
            name="secondaryNumber"
            data-test-id="monthCheck"
            value={values.month}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            options={this.cityNames()}
            placeholder="+91"
            touched={Boolean(touched.month)}
            helperText={this.handleErrorText(touched.month, errors.month) ? errors.month as string : ""}
            label={""}
          />
          <TextInputFiled
            sx={{width: {xs: "100%", sm: "23vw", md: "24.5vw", lg: "16vw"}}}
            name="secondaryNumber"
            data-test-id="full-name-input"
            placeholder="Enter secondary contact number"
            value={values.secondaryNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.secondaryNumber, errors.secondaryNumber)}
            helperText={
              this.handleErrorText(touched.secondaryNumber, errors.secondaryNumber)
                ? (errors.secondaryNumber as string)
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                  >
                    <Button style={{ color: "#7F1187", fontSize: "16px", fontFamily: "poppins", fontWeight: 400, textTransform: "none" }}>Verify</Button>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
    )
  }

  businessDetailsBox = (values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any) => {
    return(
      <Box>
      <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700, paddingBottom: "1rem" }}>Business Details:</Typography>
      <InputLabel className="labelClass" style={webstyle.inputText}>Service provided<span style={webstyle.star}>*</span></InputLabel>
      <SelectField sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="serviceProvided"
        data-test-id="monthCheck"
        value={values.serviceProvided}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        options={this.serviceProvided()}
        placeholder="Garments"
        touched={Boolean(touched.serviceProvided)}
        helperText={this.handleErrorText(touched.serviceProvided, errors.serviceProvided) ? errors.serviceProvided as string : ""}
        label={""}
      />
      <InputLabel className="labelClass" style={webstyle.inputText}>Description</InputLabel>
      <TextInputFiled sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="businessName"
        data-test-id="full-name-input"
        placeholder="vibrant colors, luxurious fabrics, and intricate craftsmanship.."
        value={values.businessName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type="text"
        error={this.isError(touched.businessName, errors.businessName)}
        helperText={this.handleErrorText(touched.businessName, errors.businessName) ? errors.businessName as string : ""}
      />
      <InputLabel className="labelClass" style={webstyle.inputText}>Products Offered</InputLabel>
      <SelectField sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="productOffered"
        data-test-id="productOffered"
        value={values.productOffered}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        options={this.productsOffered()}
        placeholder="Select Category"
        touched={Boolean(touched.productOffered)}
        helperText={this.handleErrorText(touched.productOffered, errors.productOffered) ? errors.productOffered as string : ""}
        label={""}
      />
      <Box sx={webstyle.customChip}>
        <Chip style={{
          border:
            "1px solid #CA1A7D"
        }}
          label="Flat Sale"
          onDelete={this.handleDelete}
          variant="outlined"
        />
        <Chip style={{
          border:
            "1px solid #CA1A7D"
        }}
          label="50%"
          onDelete={this.handleDelete}
          variant="outlined"
        />
      </Box>
    </Box>
    )

  }

  paymentAndBankingBox=(values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any)=>{
    return(
      <Box>
      <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700 , padding:"1rem 0rem"}}>Payment and Banking:</Typography>
      <InputLabel className="labelClass" style={webstyle.inputText}>Payment and Banking<span style={webstyle.star}>*</span></InputLabel>
      <SelectField sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="paymentMethod"
        data-test-id="paymentMethod"
        value={values.paymentMethod}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        options={this.paymentMethods()}
        placeholder="Select the location where you can deliver your products for outfit"
        touched={Boolean(touched.paymentMethod)}
        helperText={this.handleErrorText(touched.paymentMethod, errors.paymentMethod) ? errors.paymentMethod as string : ""}
        label={""}
      />

      <InputLabel className="labelClass" style={webstyle.inputText}>Bank Account Details<span style={webstyle.star}>*</span></InputLabel>
      <SelectField sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="number"
        data-test-id="monthCheck"
        value={values.month}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        options={this.bankAccountDetails()}
        placeholder="4XXXXXXSS67"
        touched={Boolean(touched.month)}
        helperText={this.handleErrorText(touched.month, errors.month) ? errors.month as string : ""}
        label={""}
      />


      <Box sx={webstyle.formInput}>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>IFSC code<span style={webstyle.star}>*</span></InputLabel>
          <TextInputFiled sx={{width: {xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
            name="ifscCode"
            data-test-id="full-name-input"
            placeholder="SBIN657646"
            value={values.ifscCode}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.ifscCode, errors.ifscCode)}
            helperText={this.handleErrorText(touched.ifscCode, errors.ifscCode) ? errors.ifscCode as string : ""}
          />
        </Box>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>Branch Name</InputLabel>
          <TextInputFiled sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
            name="branchName"
            data-test-id="full-name-input"
            placeholder="Andheri east"
            value={values.branchName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.branchName, errors.branchName)}
            helperText={this.handleErrorText(touched.branchName, errors.branchName) ? errors.branchName as string : ""}
          />
        </Box>
      </Box>
    </Box>
    )
  }

  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

   handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

 getHelperText = (isTouched:any, errors:any) => (isTouched && errors ? errors : "");
 

 handleTextField=(values:any,handleChange:any,handleBlur:any,touched:any,errors:any)=>{
  return ( <TextInputFiled sx={{width: {xs: "35vw", sm: "36vw", md: "32vw", lg: "20.5vw"}}}
  name="primaryContactPersonName"
  data-test-id="full-name-input"
  placeholder="Enter your primary name"
  value={values.primaryContactPersonName}
  handleChange={handleChange}
  handleBlur={handleBlur}
  type="text"
  error={this.isError(touched.primaryContactPersonName, errors.primaryContactPersonName)}
  helperText={this.handleErrorText(touched.primaryContactPersonName, errors.primaryContactPersonName) ? errors.primaryContactPersonName as string : ""}
/>)
 }
 
  cityNames = () => {
    return ([
      { label: 'Delhi', value: 1 },
      { label: 'Jaipur', value: 2 },
      { label: 'Noida', value: 3 },
      { label: 'Lukhnow', value: 4 },
      { label: 'Mumbai', value: 5 },
    ])
  };

  stateNames = () => {
    return [
      { label: 'Uttar Pradesh', value: 1 },
      { label: 'Maharashtra', value: 2 },
      { label: 'Rajasthan', value: 3 },
      { label: 'Delhi', value: 4 },
      { label: 'Gujarat', value: 5 },
    ];
  };

  countryNames = () => {
    return [
      { label: 'India', value: 1 },
      { label: 'United States', value: 2 },
      { label: 'United Kingdom', value: 3 },
      { label: 'Australia', value: 4 },
      { label: 'Canada', value: 5 },
    ];
  };

  pinCodes = () => {
    return [
      { label: '110001', value: 1 },
      { label: '302001', value: 2 }, 
      { label: '201301', value: 3 }, 
      { label: '226001', value: 4 }, 
      { label: '400001', value: 5 }, 
    ];
  };

  serviceProvided = () => {
    return [
      { label: 'Web Development', value: 1 },
      { label: 'Mobile App Development', value: 2 },
      { label: 'Digital Marketing', value: 3 },
      { label: 'Graphic Design', value: 4 },
      { label: 'Content Writing', value: 5 },
    ];
  };


  paymentMethods = () => {
    return [
      { label: 'Credit Card', value: 1 },
      { label: 'Debit Card', value: 2 },
      { label: 'Net Banking', value: 3 },
      { label: 'UPI', value: 4 },
      { label: 'Cash on Delivery', value: 5 },
    ];
  };

  productsOffered = () => {
    return [
      { label: 'Electronics', value: 1 },
      { label: 'Clothing', value: 2 },
      { label: 'Home Appliances', value: 3 },
      { label: 'Books', value: 4 },
      { label: 'Beauty Products', value: 5 },
    ];
  };

  bankAccountDetails = () => {
    return [
      { label: 'Account Holder Name', value: 1 }, 
      { label: 'Account Number', value: 2 },
      { label: 'Bank Name', value: 3 },
      { label: 'IFSC Code', value: 4 },
      { label: 'Account Type', value: 5 },
    ];
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Box sx={webstyle.mainContainer}>
          <Box sx={webstyle.customBox}>
            <Typography style={{ fontFamily: "poppins", fontSize: "20px", fontWeight: 600, padding: "1.5rem" }}>Business Settings</Typography>
            <Box sx={webstyle.upperSettingHeader}>
              <Box sx={webstyle.settingsHeader}>
                <Avatar style={{ width: "132px", height: "132px", color:"#A666FF", background:"#F0E5FF" , fontSize:"56px", fontWeight:600}}>H</Avatar>
                <Typography style={{ fontSize: "16px", fontWeight: 500, fontFamily: "poppins" }}>Sri Vari Boutique</Typography>
              </Box>
              <Box sx={webstyle.flexInput}>
              <Typography style={{ fontFamily:"poppins", fontSize:"16px", fontWeight:600, color:"#801187" }}>Edit</Typography>
              <CreateIcon style={{color:"#801187"}}/>
              </Box>
            </Box>
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={this.signUpWithVendorEmail}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate style={{ padding: "2rem", gap: "0.6rem" }}>
                    <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700 , paddingBottom:"1rem"}}>
                      Basic Information:
                    </Typography>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Business/Individual Name <span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="businessName"
                      data-test-id="full-name-input"
                      placeholder="Enter your business name"
                      value={values.businessName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.businessName, errors.businessName)}
                      helperText={this.handleErrorText(touched.businessName, errors.businessName) ? errors.businessName as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}>GST Number <span style={webstyle.star}>*</span></InputLabel>
                        <TextInputFiled sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="gstNumber"
                          data-test-id="full-name-input"
                          placeholder="Enter your GST number"
                          value={values.businessName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type="text"
                          error={this.isError(touched.gstNumber, errors.gstNumber)}
                          helperText={this.handleErrorText(touched.gstNumber, errors.gstNumber) ? errors.gstNumber as string : ""}
                        />
                      </Box>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}  >Primary Contact Person Name <span style={webstyle.star}>*</span></InputLabel>
                       {this.handleTextField(values,handleChange,handleBlur,touched,errors)}
                      </Box>

                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Email<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="email"
                      data-test-id="full-name-input"
                      placeholder="Enter your Email"
                      value={values.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.email, errors.email)}
                      helperText={this.handleErrorText(touched.email, errors.email) ? errors.email as string : ""}
                    />
                  {this.handleDescriptionBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <InputLabel className="labelClass" style={webstyle.inputText}>Address<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="address"
                      data-test-id="full-name-input"
                      placeholder="Enter your address"
                      value={values.address}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.address, errors.address)}
                      helperText={this.handleErrorText(touched.address, errors.address) ? errors.address as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>City<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "100px", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="city"
                          data-test-id="monthCheck"
                          value={values.city}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={this.cityNames()}
                          placeholder="Mumbai"
                          touched={Boolean(touched.city)}
                          helperText={this.handleErrorText(touched.city, errors.city) ? errors.city as string : ""}
                          label={""}
                        />
                      </Box>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>State<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="state"
                          data-test-id="monthCheck"
                          value={values.state}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={this.stateNames()}
                          placeholder="Maharashtra"
                          touched={Boolean(touched.state)}
                          helperText={this.handleErrorText(touched.state, errors.state) ? errors.state as string : ""}
                          label={""}
                        />
                      </Box>
                    </Box>
                    <Box sx={webstyle.formInput}>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Country<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="country"
                          data-test-id="monthCheck"
                          value={values.country}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={this.countryNames()}
                          placeholder="India"
                          touched={Boolean(touched.country)}
                          helperText={this.handleErrorText(touched.country, errors.country) ? errors.country as string : ""}
                          label={""}
                        />
                      </Box>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Pin Code<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="pincode"
                          data-test-id="monthCheck"
                          value={values.pincode}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          options={this.pinCodes()}
                          placeholder="400 080"
                          touched={Boolean(touched.pincode)}
                          helperText={this.handleErrorText(touched.pincode, errors.pincode) ? errors.pincode as string : ""}
                          label={""}
                        />
                      </Box>
                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Service Location<span style={webstyle.star}>*</span></InputLabel>
                    <SelectField  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="serviceLocation"
                      data-test-id="monthCheck"
                      value={values.serviceLocation}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      options={this.cityNames()}
                      placeholder="Select the location where you can deliver your products for outfit"
                      touched={Boolean(touched.serviceLocation)}
                      helperText={this.handleErrorText(touched.serviceLocation, errors.serviceLocation) ? errors.serviceLocation as string : ""}
                      label={""}
                    />
                    <Box sx={webstyle.customChip}>
                      <Chip style={{
                        border:
                          "1px solid #CA1A7D"
                      }}
                        label="Agra"
                        onDelete={this.handleDelete}
                        variant="outlined"
                      />
                      <Chip style={{
                        border:
                          "1px solid #CA1A7D"
                      }}
                        label="Mumbai"
                        onDelete={this.handleDelete}
                        variant="outlined"
                      />
                      <Chip style={{
                        border:
                          "1px solid #CA1A7D"
                      }}
                        label="Ahemdabad"
                        onDelete={this.handleDelete}
                        variant="outlined"
                      />
                      <Chip style={{
                        border:
                          "1px solid #CA1A7D"
                      }}
                        label="Delhi"
                        onDelete={this.handleDelete}
                        variant="outlined"
                      />
                      <Chip style={{
                        border:
                          "1px solid #CA1A7D"
                      }}
                        label="Jaipur"
                        onDelete={this.handleDelete}
                        variant="outlined"
                      />
                    </Box>

                    <InputLabel className="labelClass" style={{paddingTop:"1rem", fontSize:"14px", fontWeight:500,fontFamily:"poppins",color:"#334155"}}>Portfolio link (optional)</InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="portfolioLink"
                      data-test-id="full-name-input"
                      placeholder="htttp://portfolio.srivari.co.in"
                      handleChange={handleChange}
                      value={values.portfolioLink}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.portfolioLink, errors.portfolioLink)}
                      helperText={this.handleErrorText(touched.portfolioLink, errors.portfolioLink) ? errors.portfolioLink as string : ""}
                    />

                    <Box sx={webstyle.upperSettingHeader}>
                      <InputLabel className="labelClass" style={webstyle.inputText}>Social Media Link</InputLabel>
                    </Box>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="socialMediaLink"
                      data-test-id="full-name-input"
                      placeholder="Instagram Account Link"
                      value={values.socialMediaLink}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.socialMediaLink, errors.socialMediaLink)}
                      helperText={this.handleErrorText(touched.socialMediaLink, errors.socialMediaLink) ? errors.socialMediaLink as string : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                            >
                              <Button style={{ color: "#7F1187", fontSize: "14px", paddingBottom:"4rem",fontFamily: "poppins", fontWeight: 600, textTransform: "none" }}>Add</Button>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextInputFiled sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="businessName"
                      data-test-id="full-name-input"
                      placeholder="srivarishop.insta"
                      value={values.businessName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.businessName, errors.businessName)}
                      helperText={this.handleErrorText(touched.businessName, errors.businessName) ? errors.businessName as string : ""}
                    />
                  {this.businessDetailsBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                   {this.paymentAndBankingBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <Box sx={webstyle.saveButton}>
                      <Button style={{ width: "172px", height: "44px", borderRadius: "33px", background: "#F1F5F9", color: "#64748B", textTransform: "none", fontWeight:500, fontSize:"16px", fontFamily:"poppins" }}>Save changes</Button>
                    </Box>

                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Box>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  mainContainer:{
    display:"flex", 
    justifyContent:"center",
    alignItems:"center",
  },
  customChip:{
    display:"flex",
    gap:"12px"
  },
  upperSettingHeader:{
    display:"flex",
    justifyContent:"space-between"
  },
  saveButton:{
    padding:"2rem 1rem"
  },
  flexInput:{
    display:"flex",
    paddingRight: "2rem",
    gap:"12px"
  },
  star: {
   color:"red"
  },
  settingsHeader:{
    display:"flex",
    alignItems:"center",
    gap:"18px",
    paddingLeft:"1.5rem"
  },
  formInput:{
    display:"flex",
    gap:"32px",
    alignItems:"center"
  },
  inputText:{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:"poppins",
    color:"#334155",
  },
  formInput2:{
     display:"flex",
    gap:"16px",
    alignItems:"center"
  },
  description:{
     display:"flex",
     alignItems:"center",
     gap:"32px"
  },
  innerDescription:{
    display:"flex",
    flexDirection:"column"
  },
  customBox: {
   width:"90vw",
   height:"auto",
   border:"1px solid lightgrey",
   borderRadius:"8px"
  },
  settingsDetals:{
    padding:"1.5rem"
  },
  imageBox:{
    height:"25%",
    width:"10%",
    borderRadius:"50%",
    border:"1px solid black"
  }
}


const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required('Business name is required')
    .min(2, 'Business name must be at least 2 characters')
    .max(50, 'Business name must not exceed 50 characters'),

  gstNumber: Yup.string()
    .required('GST number is required')
    .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/, 'Invalid GST number'),

  primaryContactPersonName: Yup.string()
    .required('Primary contact person name is required')
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must not exceed 50 characters'),

  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),

   primaryContact: Yup.string()
    .required('Primary contact number is required')
    .matches(/^\d{10}$/, 'Primary contact must be a 10-digit number'),

  secondaryNumber: Yup.string()
    .matches(/^\d{10}$/, 'Secondary contact must be a 10-digit number')
    .notRequired(),

  address: Yup.string()
    .required('Address is required')
    .max(100, 'Address must not exceed 100 characters'),

  city: Yup.string()
    .required('City is required')
    .min(2, 'City name must be at least 2 characters')
    .max(50, 'City name must not exceed 50 characters'),

  state: Yup.string()
    .required('State is required')
    .min(2, 'State name must be at least 2 characters')
    .max(50, 'State name must not exceed 50 characters'),

  ifscCode: Yup.string()
    .required('IFSC Code is required')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code format'),

  branchName: Yup.string()
  .required(''),
  
  country: Yup.string()
    .required('Country is required')
    .min(2, 'Country name must be at least 2 characters')
    .max(50, 'Country name must not exceed 50 characters'),

  pinCode: Yup.string()
    .required('Pin Code is required')
    .matches(/^[1-9]\d{5}$/, 'Invalid Pin Code'),

  serviceLocation: Yup.string()
    .required('Service location is required')
    .max(100, 'Service location must not exceed 100 characters'),

  portfolioLink: Yup.string()
    .url('Invalid URL format')
    .notRequired(),

  socialMediaLink: Yup.string()
    .url('Invalid URL format')
    .notRequired(),
});


// Customizable Area End
