import React from "react";
// Customizable Area Start
import { Box, Typography ,InputAdornment, TextField,Button,Divider, Table,TableHead,TableCell,TableRow,TableBody, styled, IconButton, Popover} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import { Image1, Image2, Image3, Image4, Image5 } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
// Customizable Area End

import CatalogueVendorController, { Props} from "./CatalogueVendorController.web";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class CatalogueVendor extends CatalogueVendorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  productsData = () => {
    return (
      [
        {
          images:Image1,
          category: 'Haldi ceremony collection',
          name:"Haldi Lehenga",
          price: " ₹86,000",
          status: "Available",
        },    {
          images:Image2,
          category: 'Haldi ceremony collection',
          name:"Haldi Lehenga",
          price: " ₹86,000",
          status: "Unavailable",
        },
        {
          images:Image3,
          category: 'Haldi ceremony collection',
          name:"Haldi Lehenga",
          price: " ₹86,000",
          status: "Available",
        },
        {
          images:Image4,
          category: 'Haldi ceremony collection',
          name:"Haldi Lehenga",
          price: " ₹86,000",
          status: "Available",
        },
        {
          images:Image5,
          category: 'Haldi ceremony collection',
          name:"Haldi Lehenga",
          price: " ₹86,000",
          status: "Available",
        }
      ]
    )
  }
  // Customizable Area End

  render() {
    const { anchorEl , anchorFilterEl, productListData} = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const openFilter = Boolean(anchorFilterEl);
    const filterId = openFilter ? 'simple-popover': undefined;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox>
        <Box>
          <Typography style={{ fontSize: "1.3rem", fontWeight: 700, margin: "0px 2.5rem" }}>Manage Catalogue</Typography>
        </Box>
        <hr style={{ color: "#A8A29E", width: "95.5vw" }}></hr>
        <Box sx={webStyle.contentHeading}>
          <Typography style={{ fontSize: "16px", color: "#44403C", fontFamily: "Poppins" }}>Have unique products to sell? Please add the product.</Typography>
          <Button onClick={this.goTaddCatalogue} data-test-id="goTaddCatalogue" className="addCatalogueBtn">Add Catalogue</Button>
          <Box className="uploadBox">
            <Box sx={webStyle.uploadBox2}>
              <Typography style={{ fontFamily: "poppins", fontSize: "16px", color: "#78716C" }}>Total Number Catalogue</Typography>
              <Typography style={{ fontWeight: 700, fontSize: "1.5rem" }}>20</Typography>
            </Box>

          </Box>

          <Box className="mainContainer">
            <Table aria-label="a dense table" style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid lightgrey' }}>
              
              <TableHead style={{ borderLeft: '1px solid transparent', borderRight: "1px solid transparent", borderTop: "1px solid transparent" }}>
              <TableRow>
                <TableCell colSpan={5}>
                  <TextField
                  placeholder="Search"  className="searchBar"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "transparent !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "transparent !important",
                      },
                    },
                  }}
                  />
                </TableCell>
                <TableCell colSpan={5} style={{textAlign:"end"}}>
                  <Button sx={webStyle.sectonBtn} className="filterBtn" data-test-id="handleFilterClick" onClick={this.handleFilterClick}>Filter by <FilterAltOutlinedIcon/></Button>
                  <Popover
                  id={filterId}
                  open={openFilter}
                  anchorEl={anchorFilterEl}
                  onClose={this.handleFilterClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                            <Box sx={{display: 'flex', flexDirection: 'column',gap:1, width:"185px"}}>
            <Button onClick={this.handleFilterClose} sx={webStyle.sectonBtn}>Exclude out of stock</Button>
            <Divider style={{width:"100%"}}/>
            <Button onClick={this.handleFilterClose} sx={webStyle.sectonBtn}>Include out of stock</Button>
          </Box>
                </Popover></TableCell>
              </TableRow>
               <TableRow>
                <TableCell  className="tableHead">Name</TableCell>
                  <TableCell className="tableHead">Images</TableCell>
                  <TableCell className="tableHead">SKU</TableCell>
                  <TableCell className="tableHead">Ratings</TableCell>
                  <TableCell className="tableHead" style={{width:"20%"}}>Product Description </TableCell>
                  <TableCell className="tableHead" style={{width:"70%",display:"flex", alignItems:"center"}}>Date created<ArrowDropDownOutlinedIcon/></TableCell>
                  <TableCell className="tableHead">Available</TableCell>
                  <TableCell className="tableHead">Quantity</TableCell>
                  <TableCell className="tableHead">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "1px solid lightgrey" }}>
                {productListData.map((product:any, index:number) => (
                  <TableRow key={index} >
                        <TableCell className="tableCellData">{product.attributes.name}</TableCell>
                    <TableCell>
                      <img src={product.attributes.category.images} alt={product.attributes.name} className="productImage" />
                    </TableCell>
                    <TableCell className="tableCellData">{product.attributes.SKU}
                    </TableCell>
                    <TableCell className="tableCellData">5/10</TableCell>
                    <TableCell className="tableCellData" style={{width:"20%"}}>{product.attributes.description}
                    </TableCell>
                    <TableCell className="tableCellData" style={{width:"15%", textAlign:"center"}}>{moment(product.attributes.created_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell className="tableCellData">{product.attributes.is_available ? "Available" : "Unavailable"}</TableCell>
                    <TableCell className="tableCellData">{product.attributes.available_quantity}</TableCell>
                    <TableCell>
        <IconButton aria-describedby={id} data-test-id="handleClick" onClick={(event) => this.handleClick(event,product)}>
          <MoreVertIcon />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              boxShadow: "#CECECE 0px 2px 1px",
            },
          }}
        >
          <Box sx={{display: 'flex', flexDirection: 'column',gap:1, width:"100px"}}>
            <Button onClick={this.handleEditBtn} sx={webStyle.sectonBtn} data-test-id="editBtn">Edit</Button>
            <Divider style={{width:"100%"}}/>
            <Button onClick={this.deleteProductApiCall} sx={webStyle.sectonBtn} data-test-id="deleteBtn">Delete</Button>
          </Box>
        </Popover>
      </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
        </CustomBox>
      </HeaderVendorFooter>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  
  button:{
     color: 'black', 
     textTransform: 'none' ,
     fontFamily:"poppins",
     fontSize:"14px",
     fontWeight:400
  },
  contentItems:{
    display:"flex", 
    flexDirection:"column",
    gap:"1.5rem",
    height:"98px",
    paddingBottom:"7rem"
  },
  content:{
    display:"flex", 
    flexWrap:"wrap",
    padding:"1.5rem",
    justifyContent:"space-between",
    alignItems:"center"
  },
  innerHeader:{
    display:"flex",
    justifyContent:"space-between",
    height:"2rem",
    padding:"1rem",
    paddingRight:"13rem",
    alignItems:"center",
    borderBottom:"1px solid black"
  },
  contentHeader:{
    maxWidth:"100vw",
    minHeight:"auto",
    border:"1px solid lightgrey",
  },
  
  uploadBox2:{
  padding:"1rem"
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  contentHeading:{
    margin: "0px 2.5rem",
    display:"flex", 
    flexDirection:"column",
    gap:"2rem"
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  sectonBtn:{
    textTransform:"capitalize",
    textAlign:"left",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color:"#78716C"
  },
  // button:{
  //   paddingLeft:"2.5rem"
  // },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
const CustomBox = styled(Box)({
"& .tableHead":{
  fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 600,
lineHeight: "24px",
width:"10%"
},
"& .tableCellData":{
  fontFamily: "Poppins",
fontSize: "14px",
fontWeight: 400,
lineHeight: "24px",
width:"10%"
},
"& .productImage":{
  width:'70px',
  height:"80px",
  borderRadius:"8px"
},
"& .searchBar":{
  border:"1px solid #7F1287",
  width:"500px",
  borderRadius:"37px",
  height:"44px"
},
"& .filterBtn":{
border:"1px solid #A6B0BF",
padding:"10px 16px",
width:"130px",
height:"44px",
fontSize:"16px",
borderRadius:"37px",
cursor:"pointer"
},
"& .addCatalogueBtn":{
    width: "215px",
    height: "45px",
    background: "#7F1187",
    color: "white",
    fontFamily: "poppins",
    borderRadius: "45px", 
    fontWeight: 700, 
    textTransform: "none", 
    fontSize: "16px",
    "&:hover": {
      background: "#7F1187"
    }
},
"& .mainContainer": {
  borderRadius:"10px",
  border:"1px solid lightgrey",
  borderBottom:"1px solid transparent",
  "@media(max-width:1100px)": {
    width:"100%",
    overflowX:"scroll"
 },
},
"& .uploadBox":{
  background:"white",
  maxWidth:"32%",
  height:"80px",
  border:"1px solid lightGrey",
  borderRadius:"1rem",
  "@media(max-width:850px)": {
    maxWidth:"50%",
 },
 "@media(max-width:600px)": {
  maxWidth:"80%",
},
},
})
  // Customizable Area End
