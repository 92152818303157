import React from "react";
import {
  Box,InputAdornment,
  Button, Autocomplete, ListItemText,
  InputLabel, Checkbox, Stack, TextField, IconButton,
  Typography, styled, Container, Divider, MenuItem
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Loader from "../../../components/src/AppLoader.web";
import TextInputFiled from "../../../components/src/TextInputField.web";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import { Formik, FieldArray, Field, FormikErrors } from "formik";
import * as Yup from "yup";
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import SelectField from "../../../components/src/SelectField.web";
const arrowImg = require('../assets/arrow.png')
const deleteIcon = require('../assets/deleteIcon.png')

interface Options {
  value: number;
  label: string;
}
// Customizable Area End
import AddCatalogueFormController, {
  Props, ColorOption,Item, Size, ColorSet
} from './AddCatalogueFormController.web'
const validationSchema = Yup.object().shape({
  productName: Yup.string().required("Product Name is required").trim()
  .matches(/^[a-zA-Z\s]+$/, "Product Name should only contain letters")
  .test(
    "not-only-digits",
    "Product Name cannot contain only digits",
    (value) => {
      return !/^\d+$/.test(value);
    }
  )
  .max(50, "Maximum 50 characters are allowed"),
  skuCode: Yup.string(),
  productCategory: Yup.string().required("Product Category is required"),
  productDescription: Yup.string().required("Product Description is required"),
  fabricDetails: Yup.string(),
  addSize: Yup.array().of(
    Yup.object().shape({
      size: Yup.string().required('Size is required'),
      measurements: Yup.number().required('Measurements are required'),
    })
  ),
  price:Yup.number().required("Price is required"), 
  availableQuantity:Yup.number().required("Quantity is required"),
  addColor: Yup.array().of(
    Yup.object().shape({
      colorName: Yup.string().required('Color name is required'),
      colorSize: Yup.string().required('Color size is required'),
      availableQty: Yup.number()
        .required('Available quantity is required'),
      uploadImage: Yup.mixed()
        .required('Upload image is required')
        .test('fileSize', 'File size must be less than 2MB', (value) => {
          return value && value.size <= 2 * 1024 * 1024;
        })
        .test('fileType', 'Only jpg and png files are supported', (value) => {
          return value && (value.type === 'image/jpeg' || value.type === 'image/png');
        }),
    })
  ),
  selectColor: Yup.string().required("Please select Coupon"),
  selectFunction: Yup.string().required("Please select Function"),
selectedLocation: Yup.string().required("Please select Location"),
customTitle: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Title should only contain letters")
  .test(
    "not-only-digits",
    "Product Name cannot contain only digits",
    (value) => {
      return !/^\d+$/.test(value);
    }
  )
  .max(50, "Maximum 40 characters are allowed"),
  customDescription: Yup.string()
  .max(50, "Maximum 500 characters are allowed"),
  selectedFileName: Yup.mixed()
    .test(
      'fileSize',
      'File too large (Max 10MB)',
      value => value ? value.size < 10 * 1024 * 1024 : true
    )
    .test(
      'fileFormat',
      'Unsupported Format (only JPG, PNG)',
      value => value ? ['image/jpeg', 'image/png'].includes(value.type) : true
    ),
    productImages: Yup.mixed()
    .test(
      'fileCount',
      'You can upload a maximum of 10 images',
      (value: File[]) => value && value.length <= 10
    )
    .test(
      'fileSize',
      'Each file should be less than 10MB',
      (value: File[]) => value && value.every(file => file.size <= 10 * 1024 * 1024)
    )
    .test(
      'fileFormat',
      'Unsupported format. Only JPG and PNG files are allowed.',
      (value: File[]) => value && value.every(file => ['image/jpeg', 'image/png'].includes(file.type))
    ),
});

export default class AddCatalogueForm extends AddCatalogueFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  isError = (
    touched: boolean | undefined,
    errors: string | undefined
  ) => {
    return touched && !!errors;
  };
  helperText = (
    touched: boolean | undefined,
    errors: any,
  ) => {
    return touched && errors;
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    const coupons = [{ label: 'off500', value: 1 }, { label: "off2000", value: 2 }]
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <AddCatlogueMainStyle component="section" id="addcatalogueItems">
            <Typography className="addCatlogueText" component="h3">
              Add Catalogue
            </Typography>
            <Divider className="dividerStyle" />
            <Box className="mainCatalogueBox">
              <Typography className="headText">
                Product Details
              </Typography>
              <Formik
                data-test-id="Formik"
                initialValues={this.state.formData}
                validationSchema={validationSchema}
                onSubmit={() => { }}
                
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  console.log('checking error', touched)
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Box className="mainForm">
                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Name  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="productName"
                              data-test-id="productName-input"
                              placeholder="Enter your Product Name"
                              value={values.productName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched?.productName, errors?.productName)}
                      helperText={this.helperText(
                        touched?.productName,
                        errors?.productName
                      )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">SKU</InputLabel>
                            <TextInputFiled
                              name="skuCode"
                              data-test-id="skuCode-input"
                              placeholder="R-185"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.skuCode} helperText={undefined}                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Category  <span className="star">*</span></InputLabel>
                            <SelectField
                              name="productCategory"
                              data-test-id="monthCheck"
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              options={this.state.productCategory}
                              placeholder="Enter your Product Name"
                              label={""} value={values.productCategory} error={Boolean(this.handleErrorText(touched?.productCategory, errors?.productCategory))}
                              touched={touched.productCategory}
                              helperText={this.handleErrorText(touched?.productCategory, errors?.productCategory)} />
                          </Box>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Product Description  <span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="productDescription"
                            data-test-id="full-name-input"
                            placeholder="Enter your Product Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.productDescription} error={this.isError(touched?.productDescription, errors?.productDescription)}
                            helperText={this.helperText(
                        touched?.productDescription,
                        errors?.productDescription
                      )}
                          />
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Fabric details</InputLabel>
                          <TextInputFiled
                            name="fabricDetails"
                            data-test-id="full-name-input"
                            placeholder="Enter Details of fabric"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.fabricDetails} error={this.isError(touched?.fabricDetails, errors?.fabricDetails)}
                            helperText={this.helperText(
                        touched?.fabricDetails,
                        errors?.fabricDetails
                      )}
                          />
                        </Box>

                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Price  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="price"
                              data-test-id="full-name-input"
                              placeholder="Enter Product Price"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.price} error={this.isError(touched?.price, errors?.price)}
                              helperText={this.helperText(
                          touched?.price,
                          errors?.price
                        )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available Quantity  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="availableQuantity"
                              data-test-id="full-name-input"
                              placeholder="Enter Available Quantity(in stock)"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.availableQuantity} error={this.isError(touched?.availableQuantity, errors?.availableQuantity)}
                              helperText={this.helperText(
                          touched?.availableQuantity,
                          errors?.availableQuantity
                        )}
                            />
                          </Box>

                        </Box>

                        <Box className="inputFieldsSection">
  <InputLabel className="labelClass">Product Images</InputLabel>
  <TextInputFiled
                            name="productImages"
                            data-test-id="productImagesData"
                            placeholder="Upload a Product Image (Max 10 MB, JPG, PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.productImages?.map(file => file.name).join(', ') || ''}
                            type="text"
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon
                                    onClick={this.handleProductIconClick}
                                    data-test-id="handleProductIconClick" />
                                </InputAdornment>
                              ),
                            }} helperText={undefined}  />
  
  <input
    id="hiddenProductFileInput"
    type="file"
    style={{ display: 'none' }}
    accept="image/jpeg, image/png"
    multiple
    data-test-id="handleProductFileChange"
    onChange={(event) => this.handleProductFileChange(event, setFieldValue)}
  />
</Box>
<Box className="imagePreview">
  {values.productImages?.map((file, index) => (
    <Box key={index} className="imageSet">
      <img
        src={URL.createObjectURL(file)}
        alt={`preview-${index}`}
        style={{
          width: '200px',
          height: '180px',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />
      <img
        src={deleteIcon}
        className="deleteIconStyle"
        onClick={() => this.handleDeleteImage(index, setFieldValue)}
        data-test-id="delete-image"
        style={{ cursor: 'pointer' }}
      />
    </Box>
  ))}
</Box>

<Box className="inputFieldsSection">
  <InputLabel className="labelClass">
    Function<span className="star">*</span>
  </InputLabel>
  <div>
    <Autocomplete
      multiple
      id="tags-checkbox"
      options={this.state.functionList}
      data-test-id="handleFunctionSelectChange"
      disableCloseOnSelect
      value={this.state.selectedFunctions}
      onChange={(event, newValue) => this.handleFunctionSelectChange(event, newValue, setFieldValue)}
      renderTags={() => null}
      renderOption={(props, option) => {
        const isSelected = this.state.selectedFunctions.some(
          (selected) => selected.value === option.value
        );
        return (
          <MenuItem
            {...props}
            key={option.value}
            onClick={() => {
              this.toggleFunctionOption(option);
              this.handleFunctionOption(option, isSelected, setFieldValue);
            }}
            style={{ borderBottom: '1px solid #E7E5E4' }}
          >
            <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Functions for Outfits"
          data-test-id="handleFunctionSet"
          onChange={(event) => {
            handleChange(event);
          }}
          onBlur={handleBlur}
          value={values.selectFunction}
          error={this.isError(touched?.selectFunction, errors?.selectFunction)}
          helperText={this.helperText(touched?.selectFunction, errors?.selectFunction)}
        />
      )}
    />
  </div>

  <Stack direction="row" spacing={1} className="chipBox">
    {this.state.selectedFunctions.map((option) => (
      <Chip
        key={option.value}
        label={option.label}
        style={{ margin: '4px' }}
        data-test-id="handleDeleteFunctionChip"
        onDelete={() => this.handleDeleteFunctionChip(option, setFieldValue)}
        deleteIcon={
          <IconButton size="small">
            <CloseIcon fontSize="small" className="closeIcon" />
          </IconButton>
        }
      />
    ))}
  </Stack>
</Box>


                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Service Location<span className="star">*</span></InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={this.state.locationData}
                              value={this.state.selectedLocations}
                              data-test-id="handleSelectLocationChange"
                              onChange={(event, newValue) => this.handleSelectLocationChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedLocations.some(selected => selected.value === option.value);
                                return (                                    
                                    <MenuItem
            {...props}
            key={option.value}
            onClick={() => {
              this.toggleLocationOption(option);
              this.handleLocationOption(option, isSelected, setFieldValue);
            }}
            style={{ borderBottom: '1px solid #E7E5E4' }}
          ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        option.label}
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  data-test-id="handleChangeLocation"
                                  placeholder="Select the location where you can deliver your products for Outfit"
                                  onChange={(event) => handleChange(event)}
                                  onBlur={handleBlur}
                                  value={values.selectedLocation}
                                  error={this.isError(touched?.selectedLocation, errors?.selectedLocation)}
                              helperText={this.helperText(
                          touched?.selectedLocation,
                          errors?.selectedLocation
                        )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox">
                            {this.state.selectedLocations.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteLocationChip"
                                onDelete={() => this.handleDeleteLocationChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>
                        </Box>
                        
                <FieldArray name="addSize" data-test-id="FieldArray1">
                {({ push, remove }: { push: (obj: Size) => void; remove: (index: number) => void }) => (
                          <>
                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Available Sizes<span className="star">*</span></Typography>
                          <Button className="quesBtn" data-test-id="addSizeClick" onClick={() => push({ size: "", measurements: "" })}>+ Add Sizes</Button>
                        </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {values.addSize.map((_, index) => (
                  <Box className="inputFirstSection">
                  <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Size Name</InputLabel>
                            <TextField
                          name={`addSize[${index}].size`}
                          placeholder="Enter size"
                          data-test-id="sizeName"
                          value={values.addSize[index].size}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          error={
                            Boolean(
                              touched.addSize?.[index].size &&
                              (errors.addSize?.[index] as FormikErrors<Size>)?.size
                            )
                          }
                          helperText={
                            touched.addSize?.[index]?.size &&
                            (errors.addSize?.[index] as FormikErrors<Size>)?.size
                          }
                        />
                            
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Measurements</InputLabel>
                            <TextField
                             name={`addSize[${index}].measurements`}
                              data-test-id="full-name-input"
                              placeholder="Enter value"
                              value={values.addSize[index].measurements} 
                              onChange={handleChange} 
                              onBlur={handleBlur} 
                              type="text" 
                              error={
                                Boolean(
                                  touched.addSize?.[index]?.measurements &&
                                  (errors.addSize?.[index] as FormikErrors<Size>)?.measurements
                                )
                              }
                              helperText={
                                touched.addSize?.[index]?.measurements &&
                                (errors.addSize?.[index] as FormikErrors<Size>)?.measurements
                              }
                            />
                          </Box>
                          <Button className="quesBtn" data-test-id="questionBtn" type="button" onClick={() => this.createSizeApi(values, index)}>Save</Button>
                  </Box>
                  ))}
                </Box>
                </>
              )}
            </FieldArray>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Size Chart</InputLabel>
                          <TextInputFiled
                            name="selectedFileName"
                            data-test-id="sizeChart"
                            placeholder="Upload a Size Chart (Max 10 MB, JPG,PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.sizeChart || ''}
                            type="text"
                            error={this.isError(touched?.selectedFileName, errors?.selectedFileName)}
                      helperText={this.helperText(
                        touched?.selectedFileName,
                        errors?.selectedFileName
                      )}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon onClick={this.handleIconClick} data-test-id="handleIconClick"/>
                                </InputAdornment>
                              )
                            }}
                          />
                          <input
        id="hiddenFileInput"
        type="file"
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        data-test-id="handleFileChange1"
        onChange={(event) => this.handleFileChange(event, setFieldValue)}
      />
</Box>
                        <Box className="imagePreview">
  {values.selectedFileName && (
<Box className="imageSet">
    <img
      src={URL.createObjectURL(values.selectedFileName)}
      alt="Preview"
      style={{
        width: '200px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    />
    <img
        src={deleteIcon}
        className="deleteIconStyle"
        style={{ cursor: 'pointer' }}
        data-test-id="handleSizeDeleteImage"
        onClick={() => this.handleSizeDeleteImage(setFieldValue)}
      />
    </Box>
  )}
</Box>
<FieldArray name="addColor" data-test-id="FieldArray2">
              {({ push, remove }: { push: (obj: ColorSet) => void; remove: (index: number) => void }) => (
                <>
                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Available Color<span className="star">*</span></Typography>
                          <Button className="quesBtn" data-test-id="addColorQuesBtn" onClick={() => push({colorName:"", uploadImage:null, colorSize:"", availableQty:""})}>+ Add Color</Button>
                          </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {values.addColor.map((_, index) => (
                    <div>
                  <Box  key={index} className="inputFirstSection">
                    <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Name of The Color</InputLabel>
                            <TextInputFiled
                             name={`addColor[${index}].colorName`}
                              data-test-id="colorNameInput"
                              placeholder="Enter Name"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.addColor[index].colorName} error={
                                Boolean(
                                  touched.addColor?.[index]?.colorName &&
                                  (errors.addColor?.[index] as FormikErrors<ColorSet>)?.colorName
                                )
                              }
                              helperText={
                                touched.addColor?.[index]?.colorName &&
                                (errors.addColor?.[index] as FormikErrors<ColorSet>)?.colorName
                              }
                            />
                            
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Upload image</InputLabel>
                            <TextField
  name={`addColor[${index}].uploadImage`}
  data-test-id="currentUploadImage"
  placeholder="Upload Image 2MB jpg, png"
  type="text"
  value={values.addColor[index]?.uploadImage?.name || ''}
  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    handleChange({
      target: { name: `addColor[${index}].uploadImage`, value: file },
    });
  }}
  onBlur={handleBlur}
  error={Boolean(
    touched.addColor?.[index]?.uploadImage &&
    (errors.addColor?.[index] as FormikErrors<ColorSet>)?.uploadImage
  )}
  helperText={
    touched.addColor?.[index]?.uploadImage &&
    (errors.addColor?.[index] as FormikErrors<ColorSet>)?.uploadImage
  }
  InputProps={{
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end" className="inputIcons">
        <CollectionsIcon
          onClick={this.handleColorIconClick}
          data-test-id="handleColorIconClick"
        />
      </InputAdornment>
    ),
  }}
/>

                            <input
                              id="hiddenColorFileInput"
                              type="file"
                              style={{ display: 'none' }}
                              accept="image/jpeg, image/png"
                              data-test-id="handleFileChange"
                              onChange={(event) => this.handleColorFileChange(event, setFieldValue, index)}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available  Size</InputLabel>
                            <SelectField
                              name={`addColor[${index}].colorSize`}
                              data-test-id="monthCheck"
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              options={this.state.availableSize?this.state.availableSize:[]}
                              placeholder="Select Size"
                              label={""} value={values.addColor[index].colorSize}
                              error={
                                Boolean(
                                  touched.addColor?.[index]?.colorSize &&
                                  (errors.addColor?.[index] as FormikErrors<ColorSet>)?.colorSize
                                )
                              }
                              helperText={
                                touched.addColor?.[index]?.colorSize &&
                                (errors.addColor?.[index] as FormikErrors<ColorSet>)?.colorSize
                              } />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available QTY</InputLabel>
                            <TextField
                        name={`addColor[${index}].availableQty`}
                        placeholder="Enter Qty"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.addColor[index].availableQty}
                        error={
                          Boolean(
                            touched.addColor?.[index]?.availableQty &&
                            (errors.addColor?.[index] as FormikErrors<ColorSet>)?.availableQty
                          )
                        }
                        helperText={
                          touched.addColor?.[index]?.availableQty &&
                          (errors.addColor?.[index] as FormikErrors<ColorSet>)?.availableQty
                        }
                        
                      />
                          </Box>
                          <Button data-test-id="addColorQuesBtn2" className="quesBtn" type="button" onClick={() => this.createAddColor(values, index)}>Save</Button>
                  </Box>
                  </div>
                  ))}
                </Box>
                </>
              )}
            </FieldArray>
                        <Box>
                       
                       
                        <FieldArray name="items" data-test-id="FieldArray3">
              {({ push, remove }: { push: (obj: Item) => void; remove: (index: number) => void }) => (
                <>
                 <Box className="faqAskSection">
                          <Typography className="faqAsk">Custom Field</Typography>
                          <Button className="quesBtn" data-test-id="itemQuesBtn" onClick={() => push({ title: "", description: "" })}>+ Add Field</Button>
                        </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {values.items.map((_, index) => (
                  <Box className="inputFirstSection">
                  <Box className="inputFieldsSection">
                  <InputLabel className="labelClass">Title</InputLabel>
                  <TextField
                    name={`items[${index}].title`}
                    data-test-id="full-name-input"
                    placeholder="Enter Title"
                    style={{width:"100%"}}
                    value={values.items[index].title} 
                    onChange={handleChange}
                    onBlur={handleBlur} 
                    type="text"
                  />
                  </Box>
                  <Box className="inputFieldsSection">
                  <InputLabel className="labelClass">Description</InputLabel>
                  <TextField
                    name={`items[${index}].description`}
                    data-test-id="full-name-input"
                    style={{width:"100%"}}
                    placeholder="Type something..."
                    value={values.items[index].description} 
                    onChange={handleChange}
                    onBlur={handleBlur} 
                    type="text"
                  />
                  </Box>
                  </Box>
                  ))}
                </Box>
                </>
              )}
            </FieldArray>
            </Box>
                          
                         
                           <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Coupons<span className="star">*</span></InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={coupons}
                              data-test-id="handleSelectChange"
                              onChange={(event, newValue) => this.handleSelectChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedColors.some(selected => selected.value === option.value);
                                return (
                                  <MenuItem {...props} key={option.value} onClick={() => {this.toggleOption(option); this.handleCouponOption(option, isSelected, setFieldValue)}} style={{ borderBottom: "1px solid #E7E5E4" }}>
                                    <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: "20px" }}>
                                          {option.label}
                                        </div>
                                      }
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select the coupon"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="selectColor"
                                  value={values.selectColor}
                                  error={this.isError(touched?.selectColor, errors?.selectColor)}
                              helperText={this.helperText(
                          touched?.selectColor,
                          errors?.selectColor
                        )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox" data-test-id="chipBoxData">
                            {this.state.selectedColors.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteChip"
                                onDelete={() => this.handleDeleteChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>
                        </Box>

                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Frequently asked questions</Typography>
                          <Button className="quesBtn">+ Add Questions</Button>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Questions<span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="businessName"
                            data-test-id="full-name-input"
                            placeholder="Q1: Enter Your questions"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={""} helperText={undefined}
                          />
                          <Box className="ansSection">
                            <img src={arrowImg} alt="arrow" className="arrowStyle" />
                            <TextInputFiled
                              name="businessName"
                              data-test-id="full-name-input"
                              placeholder="A: Enter Response"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={""} helperText={undefined}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="Btns">
                        <Button className="cancelBtn">Cancel</Button>
                        <Button className="proceedBtn" type="submit" data-test-id="handleCreateCatalogoueApi" onClick={() => this.handleCreateCatalogoueApi(values)}>Proceed</Button>

                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </AddCatlogueMainStyle>
        </CustomContainer>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1280px)": {
    maxWidth: "95%",
  },
});

const AddCatlogueMainStyle = styled(Box)({
  padding: "32px 0 24px",
  overflowX: 'hidden',
  "& .addCatlogueText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    color: "#292524"
  },
  "& .dividerStyle": {
    backgroundColor: "#A8A29E",
    height: "1px",
    marginTop: "24px",
    marginBottom: "30px",
    width: "94%"
  },
  "& .mainCatalogueBox": {
    border: "1px solid #D6D3D1",
    borderRadius: '16px',
    backgroundColor: "#FFFFFF",
    padding: "30px 28px"
  },
  "& .headText": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    color: "#292524",
  },
  "& .labelClass": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
  },
  "& .star": {
    color: "#DC2626 !important",
  },
  "& .inputFirstSection": {
    display: 'flex',
    width: "100%",
    gap: "26px"
  },
  "& .inputFieldsSection": {
    width: '100%'
  },
  "& .mainForm": {
    width: '100%',
    paddingTop: "28px",
    paddingBottom: "80px",
    display: "flex",
    flexDirection: 'column',
    gap: "32px"
  },
  "& .cancelBtn": {
    color: "#94A3B8",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#E2E8F0",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#E2E8F0"
    }
  },
  "& .proceedBtn": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .Btns": {
    display: "flex",
    width: '100%',
    justifyContent: "flex-end",
    gap: "24px"
  },
  "& .selectOption": {
    color: "#0F172A",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
  },
  "& .Mui-checked": {
    color: "#6200EA !important",
  },
  "& .MuiCheckbox-root": {
    borderRadius: "6px !important",
  },
  "& .faqAskSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  "& .faqAsk": {
    color: "#292524",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
  },
  "& .quesBtn": {
    cursor: "pointer",
    color: "#7F1187",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    textTransform: "capitalize",
  },
  "& .ansSection": {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: "100%",
    marginTop: "-15px",
    "& > *:nth-child(2)": {
      width: "100%",
    },
  },
  "& .arrowStyle": {
    marginTop: "-30px",
    marginLeft: "15px"
  },
  "& .MuiChip-root": {
    border: "2px solid #CA1A7D",
    backgroundColor: "transparent",
    padding: '6px, 8px, 6px, 12px'
  },
  "& .MuiChip-label": {
    color: "#292524",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& .closeIcon": {
    color: "black"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .chipBox": {
    marginTop: "20px"
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .imagePreview":{
    display:"flex",
    gap:"8px",
    width:"80%",
    flexWrap:"wrap"
  },
  "& .errorStyle":{
    margin:" 8px 0px 0px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color:"rgb(211, 47, 47)"
  },
  "& .inputIcons":{
    cursor:"pointer"
  },
  "& .imageSet":{
    position:"relative",
  },
  "& .deleteIconStyle":{
    position:'absolute',
    right:"10px",
    top:'10px'
  }
});
// Customizable Area End
