import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React,{ ChangeEvent, RefObject } from "react";
import { apiCall } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
export interface ColorOption {
  label: string;
  value: number;
}
export interface Item {
  title: string;
  description: string;
}
export interface Size {
  size: string;
  measurements: string;
}
export interface ColorSet {
colorName:string;
uploadImage:File | null;
colorSize:string;
availableQty:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface FormDataOption {
  productName:string;
  skuCode:string;
  productCategory:string;
  productDescription:string;
  fabricDetails:string;
  price:string, 
      availableQuantity:string,
      selectColor:string,
      selectFunction:string[] | string,
      selectedLocation:string,
      selectedFileName:File |null,
      selectedFileNames: string[];
      sizeChart:string;
productImages: File[];
uploadImage:File | null,
items: Item[];
addSize:Size[];
addColor:ColorSet[]
}

interface StateType {
  selectedColors:ColorOption[];
  selectedFunctions:ColorOption[];
  selectedLocations: ColorOption[];
  isLoading:boolean;
  token:string;
  locationData:ColorOption[];
  functionList:ColorOption[];
  productCategory:ColorOption[];
  availableSize:ColorOption[];
  formData:FormDataOption;
  productVariant:number[]
  // Customizable Area Start
  // Customizable Area End
}
export interface ServiceLocationData {
    id: string,
    type: string,
    attributes: {
        id: number,
        location_name: string
    }
}
export interface CountriesList {
  Countries: Array<Countries>;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCatalogueFormController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  serviceLocationApiCallId:string="";
  functionDataApiCallId:string="";
  productCategoryApiCallId:string="";
  createSizeApiCallId:string="";
  availableSizeApiCallId:string="";
  createColorApiCallId:string="";
  createProductApiCallId:string=""
  fileInputRef:RefObject<HTMLDivElement[]>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInputRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      selectedColors:[],
      selectedFunctions:[],
      selectedLocations:[],
      isLoading:false,
      token:"",
      locationData:[],
      functionList:[],
      productCategory:[],
      availableSize:[],
      formData:{
        productName:"",
        skuCode:"",
        productCategory:"",
        productDescription:"",
        fabricDetails:"",
        price:"", 
        availableQuantity:"",
        selectColor:"",
        selectFunction:"",
        selectedFileName:null,
        selectedLocation:"",
        selectedFileNames: [],
        sizeChart:"",
        productImages:[],
        uploadImage:null,
        items: [{ title: "", description: "" }],
        addSize:[{size:"", measurements:""}],
        addColor:[{colorName:"", uploadImage:null, colorSize:"", availableQty:""}],
      },
      // Customizable Area Start
      productVariant:[]
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Api response
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          const mappedLocations = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.location_name
          }));
          this.setState({ locationData: mappedLocations });
          break;
          case this.functionDataApiCallId:
            const mappedFunction = responseJson.data.map((location: any) => ({
              value: location.id,
              label: location.attributes.name
            }));
            this.setState({ functionList: mappedFunction });
            break;
            case this.productCategoryApiCallId:
              const mappedCategory = responseJson.data.map((location: any) => ({
                value: location.id,
                label: location.attributes.name
              }));
              this.setState({ productCategory: mappedCategory });
              break;
              case this.availableSizeApiCallId:
                const availableSize = responseJson?.data?.map((size: any) => ({
                  value: size.id,
                  label: size.attributes.size_label
                }));
                this.setState({ availableSize: availableSize });
                break;
                case this.createSizeApiCallId:
                  this.getAvailableSizeApi()
                  break;
                  case this.createColorApiCallId:
                    let productVariant = responseJson.data.attributes.id
                    this.setState((prevState) => ({
                      productVariant: [...prevState.productVariant, productVariant],
                    }));
                    break;
                default:
      }
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken();
    this.functionDataApi()
    this.getProductCategoryApi()
    this.getAvailableSizeApi()
  };
  handleSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedColors: newValue });
    setFieldValue('selectColor', newValue);
  };
  handleDeleteChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedCoupon = this.state.selectedColors.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedColors: newSelectedCoupon });
    setFieldValue('selectColor', newSelectedCoupon);
  };
  toggleOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedColors } = prevState;
      const isSelected = selectedColors?.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedColors: selectedColors?.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedColors: [...selectedColors, optionToToggle],
        };
      }
    });
  };
  handleCouponOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedCoupons = isSelected
    ? this.state.selectedColors.filter(
        (selected) => selected.value !== option.value
      )
    : [...this.state.selectedColors, option];
  this.setState({ selectedColors: newSelectedCoupons });
  setFieldValue('selectColor', newSelectedCoupons);
  }
  handleFunctionSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedFunctions: newValue });
    setFieldValue('selectFunction', newValue);
  };
  handleDeleteFunctionChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = this.state.selectedFunctions.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedFunctions: newSelectedFunctions });
    setFieldValue('selectFunction', newSelectedFunctions);
  };
  toggleFunctionOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedFunctions } = prevState;
      const isSelected = selectedFunctions.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedFunctions: selectedFunctions.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedFunctions: [...selectedFunctions, optionToToggle],
        };
      }
    });
  };
  handleFunctionOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = isSelected
    ? this.state.selectedFunctions.filter(
        (selected) => selected.value !== option.value
      )
    : [...this.state.selectedFunctions, option];
  this.setState({ selectedFunctions: newSelectedFunctions });
  setFieldValue('selectFunction', newSelectedFunctions);
  }
  handleSelectLocationChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      this.setState({ selectedLocations: newValue });
      setFieldValue('selectedLocation', newValue);
    };

  handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = this.state.selectedLocations.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selecredLocation', newSelectedLocation);
  };
  toggleLocationOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedLocations } = prevState;
      const isSelected = selectedLocations.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedLocations: selectedLocations.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedLocations: [...selectedLocations, optionToToggle],
        };
      }
    });
  };
  handleLocationOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = isSelected
                ? this.state.selectedLocations.filter(
                    (selected) => selected.value !== option.value
                  )
                : [...this.state.selectedLocations, option];
              this.setState({ selectedLocations: newSelectedLocation });
              setFieldValue('selectedLocation', newSelectedLocation);
  }
  handleErrorText = (touched: boolean | undefined, error:any): string => {
    return touched && error ? error : '';
  };
  handleIconClick = () => {
    const inputElement = document.getElementById('hiddenFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleColorIconClick = () => {
    const inputElement = document.getElementById('hiddenColorFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleProductIconClick = () => {
    const inputElement = document.getElementById('hiddenProductFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 9.9 * 1024 * 1024) {
        setFieldValue('selectedFileName', null);
        setFieldValue('sizeChart', '');
      } else {
        setFieldValue('selectedFileName', file);
        setFieldValue('sizeChart', file.name);
      }
    }
  };
  handleColorFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void, index: number) => {
    const file = event.target.files?.[0];
    if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit check
      setFieldValue(`addColor[${index}].uploadImage`, file);
    } else {
      setFieldValue(`addColor[${index}].uploadImage`, null);
    }
  }
  handleProductFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const updatedImages = [...this.state.formData.productImages, ...files];

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        productImages: updatedImages,
      },
    }));
    setFieldValue('productImages', updatedImages);
  };

  serviceLoationApi = async() => {
    this.serviceLocationApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/service_locations",
      method: "GET",
      token: this.state.token,
    });
  }
  functionDataApi = async() => {
    this.functionDataApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/functions",
      method: "GET",
      token: this.state.token,
    });
  }
  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  getProductCategoryApi = async () => {
    this.productCategoryApiCallId = await apiCall({
      endPoint: "bx_block_categories/categories",
      method: "GET",
      token: this.state.token,
    });
    }
    createSizeApi = async (values:FormDataOption, index:number) => {
      const formData = new FormData();
      formData.append("size_label", values.addSize[index].size);
      formData.append("measurement", values.addSize[index].measurements);
      this.createSizeApiCallId = await apiCall({
        endPoint: "bx_block_multipageforms2/product_sizes",
        method: "POST",
        token: this.state.token,
        body: formData,
      });
      }
      getAvailableSizeApi = async () => {
        this.availableSizeApiCallId = await apiCall({
          endPoint: "bx_block_multipageforms2/product_sizes",
          method: "GET",
          token: this.state.token,
        });
        }
        handleDeleteImage = (index: number, setFieldValue: Function) => {
          const { productImages } = this.state.formData;
          const updatedImages = productImages.filter((_, i) => i !== index);
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              productImages: updatedImages,
            },
          }));
        
          setFieldValue('productImages', updatedImages);
        };
        handleSizeDeleteImage = (setFieldValue: (field: string, value: any) => void) => {
         setFieldValue('selectedFileName', null);
         setFieldValue('sizeChart', '');
        };

  createAddColor = async (values:FormDataOption, index:number) => {
          const formData = new FormData();
          const colorData = values.addColor?.[index];
          formData.append("name", values.addColor[index].colorName);
          formData.append("size_id", values.addColor[index].colorSize);
          formData.append("available_quantity", values.addColor[index].availableQty);
          if (colorData.uploadImage) {
            formData.append("color_image", colorData.uploadImage);
          }
          this.createColorApiCallId = await apiCall({
            endPoint: "bx_block_multipageforms2/product_colors",
            method: "POST",
            token: this.state.token,
            body: formData,
          });
   }
      
        handleCreateCatalogoueApi = async (values:FormDataOption) => {
          let formData = new FormData();
formData.append("product[name]", values.productName);
formData.append("product[SKU]", values.skuCode);
formData.append("product[category_id]", values.productCategory);
formData.append("product[description]", values.productDescription);
formData.append("product[fabric_details]", values.fabricDetails);
formData.append("product[price]", values.price);
formData.append("product[available_quantity]", values.availableQuantity);
formData.append("product[product_variant]", JSON.stringify(this.state.productVariant));
if (Array.isArray(values.selectFunction)) {
  values.selectFunction.forEach((spec: any) => {
    formData.append("product[function_ids][]", spec.value);
  });
} 
if (Array.isArray(values.selectedLocation)) {
  values.selectedLocation.forEach((spec: any) => {
    formData.append("product[service_location_ids][]", spec.value);
  });
} 
  values.items.forEach((item: Item, index: number) => {
    formData.append(`product[custom_fields][${item.title}]`, item.description);
  });
  if (values.selectedFileName) {
    formData.append("product[size_chart]", values.selectedFileName);
  }
  values.productImages.forEach((image:any, index:number) => {
    if (image?.file) {
      formData.append("product[media_files][]", image.file);
    }
  })
  this.createProductApiCallId = await apiCall({
    endPoint: "bx_block_multipageforms2/products",
    method: "POST",
    token: this.state.token,
    body: formData,
  });
        }
      // Customizable Area End
}
