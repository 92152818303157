import React from "react";
// Customizable Area Start

import { Box, Typography,InputLabel,Button,InputAdornment, styled,Grid} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

export type touched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
export type  errors = string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
import * as Yup from "yup";
import { profile } from "./assets";

// Customizable Area End

import UserProfileController, {
  Props,
} from "./UserProfileController";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import { View } from "@builder/component-library";
import TextInputFiled from "../../../../packages/components/src/TextInputField.web";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import SelectField from "../../../../packages/components/src/SelectField.web";

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

   handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

  cityNames = () => {
    return ([
      { label: '+91', value: +91},
      { label: '+88', value: +81},
    ])
  };

 getHelperText = (isTouched:any, errors:any) => (isTouched && errors ? errors : "");
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <View style={{ display: "flex", alignItems: "center"}}>
            <StyledComponents style={{width:"90vw"}}>
                <HeaderVendorFooter navigation={this.props.navigation}>
                    <Box className="profileBox">
                        <Typography className="profileTitle">Profile</Typography>
                        <Box className="profileContent">
                            <Box className="avatarContainer">
                                <Box
                                    className="imageBox"
                                    sx={{
                                        backgroundImage: `url(${this.state.selectedImage})`,
                                    }}
                                >
                                    {!this.state.selectedImage && (
                                        <Box
                                            component="span"
                                            style={{
                                                position: 'absolute',
                                                backgroundImage: `url(${profile})`,
                                                height: '150px',
                                                width: '150px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <CameraAltOutlinedIcon className="iconOverlay" />
                                            <Typography
                                                className="textOverlay"
                                                variant="h6"
                                                style={{ color: 'white', marginTop: '10px' }}
                                            >
                                                Edit Picture
                                            </Typography>
                                        </Box>
                                    )}
                                    <input data-test-id="filechange"
                                        type="file"
                                        accept="image/*"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            cursor: 'pointer',
                                        }}
                                        onChange={this.handleFileChange}
                                    />
                                </Box>
                                <Typography className="businessName">Sri Vari Boutique</Typography>
                            </Box>
                            <Box className="editContainer">
                                <Typography className="editText">Edit</Typography>
                                <CreateIcon style={{ color: "#801187" }} />
                            </Box>
                        </Box>
                        <Formik
                            initialValues={this.state.formData}
                            validationSchema={validationSchema}
                            onSubmit={this.submitFormData}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit} noValidate className="formContainer">
                                <Typography className="formTitle">Basic Information:</Typography>
                                <Grid container  sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                    <Grid item xs={12} sm={6} lg={3} md={6}>
                                        <InputLabel className="labelClass">
                                            Full Name <span className="required">*</span>
                                        </InputLabel>
                                        <TextInputFiled
                                          sx={{ 
                                            width: { xs: "84vw", sm: "35vw", lg: "20vw" }
                                          }} 
                                            name="fullName"
                                            placeholder="Enter your full name"
                                            value={values.fullName}
                                            type="text"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={this.isError(touched.fullName, errors.fullName)}
                                            helperText={this.getHelperText(touched.fullName, errors.fullName)}
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} sm={6} lg={3} md={6}>
                                        <InputLabel className="labelClass">
                                            Email <span className="required">*</span>
                                        </InputLabel>
                                        <TextInputFiled
                                            sx={{ 
                                              width: { xs: "84vw", sm: "35vw", lg: "20vw" }
                                            }} 
                                            name="email"
                                            placeholder="Enter your email"
                                            value={values.email}
                                            type="text"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={this.isError(touched.email, errors.email)}
                                            helperText={this.getHelperText(touched.email, errors.email)}
                                        />
                                    </Grid>
                                </Grid>
    
                                <InputLabel className="labelClass">Contact Number</InputLabel>
                                <Box sx={{ display: "flex", gap: "1.4rem", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center" }}>
                                    <SelectField
                                        sx={{ width: { xs: "100%", sm: "10vw" , lg:"7vw"} }}
                                        name="number"
                                        label=""
                                        value={values.number}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}
                                        options={this.cityNames()}
                                        placeholder="+91"
                                        touched={Boolean(touched.number)}
                                        helperText={this.getHelperText(touched.number, errors.number)}
                                    />
                                    <TextInputFiled
                                        sx={{ width: { xs: "100%", sm: "64vw", lg:"33.5vw" , md:"65.5vw"} }}
                                        name="contactNumber"
                                        placeholder="Enter contact number"
                                        value={values.contactNumber}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        type="text"
                                        error={this.isError(touched.contactNumber, errors.contactNumber)}
                                        helperText={this.getHelperText(touched.contactNumber, errors.contactNumber)}
                                    />
                                </Box>
    
                                <Typography className="formTitle">Password</Typography>
                                <InputLabel className="labelClass">Current Password</InputLabel>
                                <TextInputFiled
                                    sx={{ width: { xs: "100%", sm: "77vw", lg:"42vw", md:"77vw"}, paddingTop: "1.5rem" }} 
                                    name="password"
                                    type="password"
                                    placeholder="Enter your password"
                                    value={values.password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={this.isError(touched.password, errors.password)}
                                    helperText={this.getHelperText(touched.password, errors.password)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CreateIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button className="saveButton" type="submit">Save changes</Button>
                            </Form>
                            )}
                        </Formik>
                    </Box>
                </HeaderVendorFooter>
            </StyledComponents>
        </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Full name is required')
        .min(2, 'Full name must be at least 2 characters')
        .max(50, 'Full name must not exceed 50 characters'),
        email: Yup.string()
        .required('Email is required')
        .email('Invalid email format')
}
);

const StyledComponents = styled(Box)({
  '& .formContainer': {
    paddingTop: "2rem",
  },
  '& .profileBox': {
    width: '90vw',
    border: '1px solid #D6D3D1',
    height: 'auto',
    borderRadius: '8px',
    padding: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      width: '90vw',
      padding: '1rem',
    },
    '@media (max-width: 480px)': {
      width: '95vw',
      padding: '0.5rem',
    },
  },
  '& .profileTitle': {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'poppins',
    paddingBottom: '1rem',
  },
  '& .profileContent': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .required':{
    color:"red"
  },
  '& .imageBox': {
    position: 'relative',
    width: 150,
    height: 150,
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '@media (max-width: 480px)': {
      width: 120,
      height: 120,
    },
  },
  '& .textOverlay': {
    position: 'absolute',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'poppins',
    fontWeight: 700,
  },
  '& .iconOverlay': {
    position: 'absolute',
    color: 'white',
    fontSize: '40px',
    top: '20%',
    width: '20px',
    height: '18px',
    paddingTop: '1rem',
  },
  '& .avatarContainer': {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .businessName': {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'poppins',
  },
  '& .editContainer': {
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      paddingTop: '1rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .editText': {
    fontFamily: 'poppins',
    fontSize: '16px',
    fontWeight: 600,
    color: '#801187',
  },
  '& .formTitle': {
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 700,
    paddingBottom: '1rem',
  },
  '& .labelClass': {
    fontSize: '14px',
    fontWeight: 500,
    color: '#334155',
    fontFamily: 'poppins',
  },
  '& .saveButton': {
    width: '172px',
    height: '44px',
    borderRadius: '33px',
    background: '#F1F5F9',
    color: '#64748B',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'poppins',
    marginTop: '2rem',
    '@media (max-width: 480px)': {
      width: '100%',
      height: '36px',
    },
  },
  '& .inputContainer': {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  '& .numberContainer': {
    display: 'flex',
    gap: '1.4rem',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});
